import { Component, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  menuList: any;
  openSidebar: boolean = false;

  @Input() set menuData(data: any) {
    if (data) {
      this.menuList = data;
    }
  }

  constructor(private router: Router) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((_event: NavigationEnd) => {
        this.menuList;
      });
  }

  ngOnInit() {}

  showSubmenu(itemEl: HTMLElement) {
    itemEl.classList.toggle('showMenu');
  }

  showLeafMenu(leafEl: HTMLElement) {
    leafEl.classList.toggle('showSubMenu');
  }
}
