import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DataService {

    private data = new BehaviorSubject("");
    currentData = this.data.asObservable();
    private menuId = new BehaviorSubject(0);
    rotationContent = this.menuId.asObservable();
    private content = new BehaviorSubject({});
    private index = new BehaviorSubject(0);
    rotationData = this.content.asObservable();
    rotationDataIndex = this.index.asObservable();
    constructor() { }

    setData(data) {
        this.data.next(data);
    }

    setActiveMenu(menuId) {
        this.menuId.next(menuId);
    }

    setRotationDetail(index, content) {
        this.content.next(content);
        this.index.next(index);
    }
}